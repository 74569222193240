import React, { Fragment } from "react"
import classNames from "classnames"
import formatNumber from "format-number"
import { format as formatDate } from "date-fns"

import ReportLayout from "../../components/ReportLayout/ReportLayout"
import houseIcon from "../../images/house.svg"
import apartmentsIcon from "../../images/apartments.svg"
import styles from "./market-profile.module.css"

const MarketProfilePage = ({ location }) => (
  <ReportLayout urlSearch={location.search}>
    {({ report }) => {
      const housesStats = report.stats.find(x => x.pt === "H")
      const unitsStats = report.stats.find(x => x.pt === "U")
      const month = new Date(report.mnt)

      return (
        <div className="container">
          <div className="row">
            <Card
              header="Housing Stock:"
              left={
                <Fragment>
                  <HouseIcon />
                  <div className={styles.figureValue}>
                    {formatCount(housesStats.dwellings)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(housesStats.dwellings_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Houses</div>
                </Fragment>
              }
              right={
                <Fragment>
                  <ApartmentsIcon />
                  <div className={styles.figureValue}>
                    {formatCount(unitsStats.dwellings)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(unitsStats.dwellings_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Units</div>
                </Fragment>
              }
              footer={
                <div>Housing Stock as at {formatDate(month, "MM/yy")}</div>
              }
            />
            <Card
              header="For Sale:"
              left={
                <Fragment>
                  <HouseIcon />
                  <div className={styles.figureValue}>
                    {formatCount(housesStats.som)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(housesStats.som_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Houses</div>
                </Fragment>
              }
              right={
                <Fragment>
                  <ApartmentsIcon />
                  <div className={styles.figureValue}>
                    {formatCount(unitsStats.som)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(unitsStats.som_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Units</div>
                </Fragment>
              }
              footer={
                <div>Stock For Sale as at {formatDate(month, "MM/yy")}</div>
              }
            />
            <Card
              header="Days on Market:"
              left={
                <Fragment>
                  <HouseIcon />
                  <div className={styles.figureValue}>
                    {formatCount(housesStats.dom_12mths)}
                  </div>
                  <div className={styles.figureDwellingType}>Houses</div>
                </Fragment>
              }
              right={
                <Fragment>
                  <ApartmentsIcon />
                  <div className={styles.figureValue}>
                    {formatCount(unitsStats.dom_12mths)}
                  </div>
                  <div className={styles.figureDwellingType}>Units</div>
                </Fragment>
              }
              footer={<div>Average Days on market Past 12 months</div>}
            />
            <Card
              header="Total Listings:"
              left={
                <Fragment>
                  <HouseIcon />
                  <div className={styles.figureValue}>
                    {formatCount(housesStats.listings_12mths)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(housesStats.listings_12mths_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Houses</div>
                </Fragment>
              }
              right={
                <Fragment>
                  <ApartmentsIcon />
                  <div className={styles.figureValue}>
                    {formatCount(unitsStats.listings_12mths)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(unitsStats.listings_12mths_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Units</div>
                </Fragment>
              }
              footer={<div>Total Listings the past 12 months</div>}
            />
            <Card
              header="Rental Listings:"
              left={
                <Fragment>
                  <HouseIcon />
                  <div className={styles.figureValue}>
                    {formatCount(housesStats.rental_listings_12mths)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(housesStats.rental_listings_12mths_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Houses</div>
                </Fragment>
              }
              right={
                <Fragment>
                  <ApartmentsIcon />
                  <div className={styles.figureValue}>
                    {formatCount(unitsStats.rental_listings_12mths)}
                  </div>
                  <div className={styles.figureValue}>
                    {formatPercentage(unitsStats.rental_listings_12mths_perc)}
                  </div>
                  <div className={styles.figureDwellingType}>Units</div>
                </Fragment>
              }
              footer={<div>Rental Listings Past 12 months</div>}
            />
            <Card
              header="Asking Rents:"
              left={
                <Fragment>
                  <HouseIcon />
                  <div className={styles.figureValue}>
                    {formatCurrency(housesStats.typical_rent)}
                  </div>
                  <div className={styles.figureDwellingType}>Houses</div>
                </Fragment>
              }
              right={
                <Fragment>
                  <ApartmentsIcon />
                  <div className={styles.figureValue}>
                    {formatCurrency(unitsStats.typical_rent)}
                  </div>
                  <div className={styles.figureDwellingType}>Units</div>
                </Fragment>
              }
              footer={<div>Typical Weekly asking Rents</div>}
            />
          </div>
        </div>
      )
    }}
  </ReportLayout>
)

// ============================================================================
// SUB-COMPONENTS
// ============================================================================

const HouseIcon = () => (
  <img
    className={classNames("img-fluid", styles.icon)}
    src={houseIcon}
    alt="House"
  />
)

const ApartmentsIcon = () => (
  <img
    className={classNames("img-fluid", styles.icon)}
    src={apartmentsIcon}
    alt="Apartments"
  />
)

const Card = ({ header, left, right, footer }) => (
  <div className="col-sm-6 col-md-4">
    <div className={styles.cardBody}>
      <h4 className={styles.cardHeader}>{header}</h4>
      <div className="row">
        <div className="col">{left}</div>
        <div className="col">{right}</div>
      </div>
      <div className={styles.cardFooter}>{footer}</div>
    </div>
  </div>
)

// ============================================================================
// HELPERS
// ============================================================================

const formatCurrency = formatNumber({ prefix: "$" })
const formatPercentage = formatNumber({ suffix: "%" })
const formatCount = formatNumber()

export default MarketProfilePage
